import React from 'react';
import { Link } from 'react-router-dom';
import { StoreConfigProvider } from '@hpstellar/core/context';

import useDeviceInfo from '../../hooks/useDeviceInfo';
import { Helpers } from '../../core';

const getDeviceBreakpoint = device => {
    switch (device) {
        case 'mobile':
            return 'sm';
        case 'tablet':
            return 'md';
        default:
            return 'xxl';
    }
};

/**
 * Memoize config because it should only every be the value from initialization
 */
const getStoreConfig = Helpers.memoize(
    device => {
        return {
            locale: 'en-US',
            currency: 'USD',
            disclaimer: null,
            preRenderViewport: getDeviceBreakpoint(device),
            routerLinkComponent: React.forwardRef(function CustomLink({ href, ...props }, ref) {
                return <Link ref={ref} to={href} {...props} />;
            }),
        };
    },
    () => {
        //dont use device for memoization key, because again we only need the initial value
        return 'init ';
    },
);

export default ({ children }) => {
    //TODO: could potential get more of these context settings from SiteConfig redux store
    const { device } = useDeviceInfo(['device']);
    const stellarConfig = getStoreConfig(device);

    return <StoreConfigProvider config={stellarConfig}>{children}</StoreConfigProvider>;
};
