import { Helpers } from '../helpers';
import { formatSpecsForEmail } from './util';

import Services from './services';

export const CartAPI = function (apiConfig, http) {
    let self = this;
    self.hpServices = new Services(apiConfig, http);
    self.commonParams = {
        storeId: apiConfig.siteInfo.storeId,
        langId: apiConfig.siteInfo.langId,
        catalogId: apiConfig.siteInfo.catalogId,
        cql: 40,
        cal: 15000,
    };

    function setParamOptions(addCartParams, options) {
        let params = Object.assign({}, addCartParams);
        const onErr = () => {
            throw 'Invalid options information provided to addToCart';
        };

        if (!Object.keys(options).length) {
            return params;
        }

        if (options.isGC) {
            const { confirmEmail, recipientEmail, toName, fromName, message, type, itemId, price } = options;

            if (!itemId || !fromName || !toName) {
                onErr();
            }

            params.isGC = options.isGC;
            params.gcPrice = price;
            params.gcMessage = message;
            params.gcToName = toName;
            params.gcFromName = fromName;
            params.gcType = type;
            params.gcPartNumber = itemId;
            params.catEntryId = itemId;

            if (type === 'VGC') {
                (!confirmEmail || !recipientEmail || confirmEmail !== recipientEmail) && onErr();
                params.gcMail = recipientEmail;
            }
        } else if (options.eCarepack.length) {
            const { eCarepack } = options;

            eCarepack.forEach(ec => {
                const { elgblSku, elgblSerialHwSku } = ec;
                let { index } = ec;
                /*   if(++index){
          params[`elgblSKU_${index}`] = elgblSku;
        } else {
          params[`elgblSKU`] = elgblSku;
        }*/

                //AddToCart service doesn't support _INDEX sytax for elgblSku
                //These means there could be a case where multiple eCarepacks are in add to cart
                //With different elgigble sku IDs. In this case they will all get associated with the same hardware sku
                //One thing we could do is if we see multiple with different hardware skus we could break them out in their own add to cart call
                //before making the multi sku add. But for now we will just ignore this
                if (elgblSerialHwSku && elgblSku) {
                    params[`elgblSerialHwSku`] = elgblSerialHwSku;
                    params[`elgblSku`] = elgblSku;
                } else {
                    params[`elgblSku`] = elgblSku;
                }
            });
        } else {
            // remove extra param
            delete options.eCarepack;
            delete options.baseSKU;
            delete options.userInputBaseSKU;
            delete options.removeExistingAssociation;
            Object.assign(params, options);
        }

        return params;
    }

    /**
     * [addToCart adds one or more products to cart]
     * @param {[String or Object]} product [Accepts either a string itemId or array of ids]
     * @param {[Number]} qty [The quantity for the itemId provided in the first argument]
     * @param {[Object]} options [meta options to be passed into add to cart function]
     * @return {[Promise]} [Promise from the http request]
     */
    self.addToCart = (id, qty, options) => {
        var params = Object.assign({}, apiConfig.siteInfo, {
            orderId: '.',
            calculationUsage: '-1%2C-2%2C-5%2C-6%2C-7', //need to see if these changes if logged in
            inventoryValidation: 'true',
            URL: '', //need to see if these changes if logged in
            updatePrices: 1, //need to see if these changes if logged in
            isGC: 'N', //need to see if these changes if logged in
            requesttype: 'ajax',
        });
        //if null or undefined is explicetly passed into qty
        qty = qty || 1;

        //if array of only one item, convert to single addToCat
        id = id && id.constructor === Array && id.length === 1 ? id[0] : id;
        qty = qty && qty.constructor === Array && qty.length === 1 ? qty[0] : qty;

        if (id && id.constructor === Array) {
            const ids = id;
            ids.forEach((itemId, i) => {
                params[`catEntryId_${i + 1}`] = itemId;
                params[`quantity_${i + 1}`] = qty && qty.constructor === Array ? qty[i] : qty;
            });
        } else {
            params.catEntryId = id;
            params.quantity = qty;
        }

        try {
            if (options) {
                params = setParamOptions(params, options);
            }

            return http.post('AddToCartAjax', Helpers.parseFormData(params), {
                excludePrivateStoreID: true,
            });
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    };

    self.updateFreeGiftSelection = (rewardOptionId, itemIds, qty = 1, options) => {
        //include site params with add to cart specific params
        var params = Object.assign(
            {
                orderId: '.', //need to see if these changes if logged in,
                rewardOptionId,
                calculationUsage: '-1%2C-2%2C-3%2C-4%2C-5%2C-6%2C-7', //need to see if these changes if logged in
                allocate: '***',
                backorder: '***',
                requesttype: 'ajax',
            },
            options || {},
        );
        let payload = Helpers.parseFormData(params);
        payload = itemIds.reduce((r, id, i) => {
            r += `&catEntryId=${id}&quantity=${qty && qty.constructor === Array ? qty[i] : qty}`;
            return r;
        }, payload);

        return http.post('AjaxOrderChangeServiceRewardOptionUpdate', payload);
    };

    self.updateCartItem = (orderItemId, qty, options) => {
        //include site params with add to cart specific params
        var params = Object.assign(
            {
                orderId: '.', //need to see if these changes if logged in
                inventoryValidation: 'true',
                calculationUsage: '-1%2C-2%2C-3%2C-4%2C-5%2C-6%2C-7', //need to see if these changes if logged in
                check: '*n', //need to see if these changes if logged in
                requesttype: 'ajax',
            },
            apiConfig.siteInfo,
            options || {},
        );
        if (orderItemId && orderItemId.constructor === Array) {
            orderItemId.forEach((itemId, i) => {
                params[`orderItemId_${i + 1}`] = itemId;
                params[`quantity_${i + 1}`] = qty && qty.constructor === Array ? qty[i] : qty;
            });
        } else {
            params.orderItemId = orderItemId;
            params.quantity = qty;
        }

        return http.post('AjaxOrderChangeServiceItemUpdate', Helpers.parseFormData(params));
    };

    self.deleteCartItem = orderItemId => {
        var params = Object.assign(
            {
                orderId: '.', //need to see if these changes if logged in
                inventoryValidation: 'true',
                calculationUsage: '-1%2C-2%2C-3%2C-4%2C-5%2C-6%2C-7', //need to see if these changes if logged in
                check: '*n', //need to see if these changes if logged in
                requesttype: 'ajax',
            },
            apiConfig.siteInfo,
        );
        if (orderItemId && orderItemId.constructor === Array) {
            orderItemId.forEach((itemId, i) => {
                params[`orderItemId_${i + 1}`] = itemId;
            });
        } else {
            params.orderItemId = orderItemId;
        }

        return http.post('AjaxOrderChangeServiceItemDelete', Helpers.parseFormData(params));
    };

    self.getCTOPrice = (catentryId, skus, usertype = '', crossSell = '', pStoreId = '', currentDate) => {
        let params = {
            storeId: apiConfig.siteInfo.storeId,
            usertype,
            catentryId,
            skus: skus.join(','),
            crossSell,
            pStoreId,
            currentDate: currentDate || Helpers.date.etrDateFormat(new Date().toUTCString()),
        };
        if (usertype === 'CC_CSR') {
            params.isCCFlow = true;
        }
        return http
            .get(`PriceStrikeThroughDynamicKitDisplayCmd`, { params })
            .then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return { error: 'SEE PRICE IN THE CART' };
                }
            })
            .catch(e => {
                return { error: 'SEE PRICE IN THE CART' };
            });
    };

    /**
     *
     * @param {string} parentPartNum
     * @param {string[]} partNumbers
     * @param {boolean} fromAttach
     * @param {function} productMapper
     * @returns
     */
    self.getCTOServices = (parentPartNum, partNumbers, fromAttach, productMapper) => {
        const params = {
            storeId: apiConfig.siteInfo.storeId,
            langId: apiConfig.siteInfo.langId,
            parentPartNum,
            partNumbers: partNumbers.join(','),
            fromAttach,
        };
        return http.get(`HPCTOServices`, { params }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                const { serviceData, products } = Object.keys(data).reduce(
                    (r, sku) => {
                        const { inventoryData, priceData, productData } = data[sku] || {};
                        if (productData) {
                            r.serviceData.inventoryData.push(inventoryData);
                            r.serviceData.priceData.push(priceData);
                            r.products.push(
                                typeof productMapper === 'function' ? productMapper(productData) : productData,
                            );
                        }
                        return r;
                    },
                    { serviceData: { inventoryData: [], priceData: [] }, products: [] },
                );
                const { serviceData: prices } = self.hpServices.mergeServiceData(serviceData);
                return { products, prices };
            } else {
                return Promise.reject({});
            }
        });
    };

    self.emailCTO = (
        baseConfig,
        primarySpecs = [],
        additionalSpecs = [],
        recepients,
        sender,
        txtMsg,
        salePrice,
        regularPrice,
        editTextMessageConf,
        pdpUrl,
        authToken,
        token,
    ) => {
        //email list should be comma delimited, even though most forms expect semi-colon delimited
        const emailList = recepients.indexOf(';') ? recepients.split(';').join(',') : recepients;
        const formData = {
            storeId: apiConfig.siteInfo.storeId,
            langId: apiConfig.siteInfo.langId,
            primaryShareconfigurl: formatSpecsForEmail(primarySpecs),
            shareconfigurl: formatSpecsForEmail(additionalSpecs),
            catName: baseConfig.title,
            mainProdImage: baseConfig.image || '',
            recepients: emailList,
            sender,
            txtMsg,
            PSTProm: Helpers.formatCurrency(salePrice, '$', true).replace(/,/gi, ''),
            PSTBase: Helpers.formatCurrency(regularPrice, '$', true).replace(/,/gi, ''),
            partNum: baseConfig.sku,
            editTextMessageConf,
            pdpUrl,
            authToken,
            token,
        };

        return http({
            method: 'post',
            url: 'emailCTO',
            data: Helpers.paramsToString(formData, false),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }).then(resp => {
            //TODO: handle response once i figure out how to get the auth token values
        });
    };

    self.getGiftCardBalance = (gcNumber, gcPin, token) => {
        const formData = {
            storeId: apiConfig.siteInfo.storeId,
            langId: apiConfig.siteInfo.langId,
            catalogId: apiConfig.siteInfo.catalogId,
            orderId: 1223,
            ormOrderId: 123,
            skipCaptchaFlag: false,
            gcNumber,
            gcPin,
            token,
            'g-recaptcha-response': token,
        };

        return http({
            method: 'post',
            url: 'GCBalanceCmd',
            data: Helpers.paramsToString(formData, false),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        }).then(resp => {
            const { data } = resp || {};
            try {
                const serviceResponse = JSON.parse(data.replace('/*', '').replace('*/', ''));
                if (serviceResponse.paymentGCMessage == 'NE') {
                    return { amount: serviceResponse.bAmt };
                } else {
                    return { error: serviceResponse.paymentGCMessage, amount: 0 };
                }
            } catch (e) {
                return { error: 'Please try again.', amount: 0 };
            }
        });
    };

    self.setPromoCode = (params = {}) => {
        const formData = {
            ...self.commonParams,
            ...params,
        };
        return http({
            method: 'post',
            url: 'AjaxPromotionCodeManage',
            data: Helpers.paramsToString(formData, false),
        }).then(resp => {
            const { data } = resp || {};
            try {
                return JSON.parse(data.replace('/*', '').replace('*/', ''));
            } catch (e) {
                return { errorMessage: 'Please try again.' };
            }
        });
    };

    self.redeemPoints = redeemPoints => {
        const { storeId, catalogId, langId } = self.commonParams;
        return http({
            method: 'post',
            url: 'AjaxLoyaltyRewardsApply',
            data: Helpers.paramsToString({ storeId, catalogId, langId, redeemPoints }, false),
        }).then(resp => {
            const { data } = resp || {};
            try {
                return JSON.parse(data.replace('/*', '').replace('*/', ''));
            } catch (e) {
                return { errorMessage: 'Please try again.' };
            }
        });
    };

    self.setZipCode = (params = {}) => {
        const formData = {
            ...self.commonParams,
            ...params,
        };
        return http({
            method: 'get',
            url: 'AjaxOrderShipEstimate',
            params: formData,
        }).then(resp => {
            const { data } = resp || {};
            try {
                return JSON.parse(data.replace('/*', '').replace('*/', ''));
            } catch (e) {
                return { errorMessage: 'Please try again.' };
            }
        });
    };

    self.updateShipMethod = (params = {}) => {
        const formData = {
            ...self.commonParams,
            ...params,
            cal: 10000,
        };
        return http({
            method: 'post',
            url: 'AjaxOrderShipMethodUpdate',
            data: Helpers.paramsToString(formData, false),
        }).then(resp => {
            const { data } = resp || {};
            try {
                return JSON.parse(data.replace('/*', '').replace('*/', ''));
            } catch (e) {
                return { errorMessage: 'Please try again.' };
            }
        });
    };
};
