import { Helpers } from '../core/src/helpers';
import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { ROOT_FILTER_KEYS } from '../plp/constants';

const storeAppAPI = new StoreAppAPI();

/**
 * Helpers functions for sale apge specific filters
 */
export const urlParser = match => {
    const { filters } = match.params;
    let queryObj = Helpers.parseFilters(filters);
    //treat primary group key as category filters
    queryObj.query = queryObj.query || {};
    //loop through query keys and add facets scope for non root filters
    Object.keys(queryObj.query).reduce((r, k) => {
        if (!(k in ROOT_FILTER_KEYS)) {
            let alias = `facets.${k}`;
            r[alias] = r[k];
            delete r[k];
        }
        return r;
    }, queryObj.query);
    return queryObj.query;
};

/**
 * Format search request specific to slp pages
 * @param {*} query
 * @param {*} pgKey
 * @param {*} activePG
 * @param {*} disableAutoHide
 * @param {*} autoHideType
 */
export const slpSearch = (query, pgKey, activePG, disableAutoHide, autoHideType, _preview) => {
    let [, , tabName] = pgKey.split(':');
    const { name } = activePG || {};
    const { categoryFacets = [] } = activePG.metaData || {};
    let q = {
        aggregates: { price: 1, rating: 1 },
        filters: Object.keys(query)
            .sort()
            .reduce(
                (result, key) => {
                    let value = query[key];
                    if (key !== 'price' && Array.isArray(value)) {
                        result[key] = value.sort().join(',');
                    } else {
                        result[key] = value;
                    }
                    return result;
                },
                {
                    cat: categoryFacets[0] && (categoryFacets[0].key || categoryFacets[0]._id),
                },
            ),
        from: 0,
        _source: ['sku'],
    };

    //modify query for auto hide functionality
    if (!disableAutoHide) {
        if (['hideNoIR', 'hideNoIROOS'].includes(autoHideType)) {
            q.filters['facets.sale'] = 'sale';
        }
        if (['hideOOS', 'hideNoIROOS'].includes(autoHideType)) {
            q.filters.instock = true;
        }
    }

    // temp to fix previews for scheduled versions
    if (_preview) {
        q._preview = _preview;
    }
    return storeAppAPI.product.es
        .productGroup(pgKey, q)
        .then(resp => {
            //format response to support custom aggregates
            let { data } = resp;
            //add product group key to each facet for tracking
            let facets = (data.facets || []).map(f => {
                f.metricsId = `tab.${tabName}`;
                return f;
            });
            delete data.facets;
            let { category } = data;
            const customAggregations = { facets, category };

            let dynamicSEO = getDynamicSEOData(data, customAggregations, query, name || tabName);
            return { data, customAggregations, dynamicSEO };
        })
        .catch(e => {
            //catch this exception to try to avoid SSR issues
            return { data: {}, customAggregations: { facets: [], category: {} } };
        });
};

export const getDynamicSEOData = (data, customAggregations, query, tabName) => {
    const { category } = data || {};
    const { seo } = category || {};
    let dynamicSEO = {};

    try {
        if (seo?.facets.facetPriority && customAggregations?.facets) {
            const { facets } = customAggregations;
            const { facetPriority } = seo.facets;
            //price is alawys a range so ignore it when checking for multi facet selections
            const { price, ...restQuery } = query;
            const noMultiFacetSelections = Object.values(restQuery).filter(v => v?.length > 1).length === 0;
            let useDefaultCategory = false;
            //convert facet priority list to alias list
            const facetPriorityAlias = facetPriority.reduce((r, id) => {
                const f = facets.find(facet => facet._id === id);
                //if category facet is not in user selected facets, use default category
                if (id === 'gageR' && !f) {
                    useDefaultCategory = true;
                }
                if (f) {
                    r.push(f.alias);
                }
                return r;
            }, []);
            const selectedFacets = Object.keys(query).map(key => key.split('.').pop());
            //if selected facets is less than 4 and the selected facets are in the facet priority list
            if (
                noMultiFacetSelections &&
                selectedFacets.length > 0 &&
                selectedFacets.length < 4 &&
                selectedFacets.every(facet => facetPriorityAlias.includes(facet))
            ) {
                dynamicSEO.selfCanonical = true;
                //add meta labels for the selected facet value display names in order of the facet priority list
                dynamicSEO.metaLabels = facetPriorityAlias.reduce((r, f) => {
                    if (selectedFacets.includes(f)) {
                        if (f === 'price') {
                            const [min, max] = query[f];
                            const maxValue = max * 1;
                            if (maxValue <= 0) {
                                return r;
                            }
                            r.push(maxValue < 99999 ? `Under $${max}` : 'Premium');
                            return r;
                        }
                        const facet = facets.find(facet => facet.alias === f);
                        const fv = facet?.facetValues?.find(
                            fv => fv.alias === (query[`facets.${facet.alias}`] || query[facet.alias])?.[0],
                        );
                        let labelValue = fv.displayName;
                        //TODO: remove these tempfixes in favor of an aditional alias field for meta labels
                        if (f === 'touchscreen' && labelValue && labelValue.toLowerCase().indexOf('screen') < 0) {
                            labelValue = labelValue + ' Screen';
                        }
                        if (f === 'sides' && fv.alias && ['Yes', 'No'].includes(labelValue)) {
                            //used the alais capitalizing the first letter of each word
                            labelValue = Helpers.titleCase(fv.alias);
                        }
                        //remove & up suffix from display name
                        fv && fv.displayName && r.push(labelValue.replace(' & up', '').replace(' & ', ' and '));
                    }
                    return r;
                }, []);
                if (useDefaultCategory && tabName && dynamicSEO.selfCanonical) {
                    dynamicSEO.metaLabels.push(tabName);
                }
            }
        }
    } catch (e) {
        console.log('Error parsing dynamic seo data', e);
    }

    return dynamicSEO;
};
