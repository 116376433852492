import React from 'react';

/**
Defines the search context
@component SearchContext
@import SearchContext
@returns {ReactElement} The react context
*/
export const SearchContext = React.createContext({
    updateQuery: () => {},
    setQuery: () => {},
    setSort: () => {},
    setPage: () => {},
    updateFilterUrl: () => {},
    getFilterUrl: () => {},
    searchInProgress: false,
    query: {},
    results: [],
    currentPage: 0,
    totalPages: 0, //total number pages available
    hits: 0,
    sort: {},
    filterCount: 0,
    aggregations: {},
    customAggregations: undefined,
    customContext: undefined,
    seralizedQuery: '',
    dynamicSEO: {},
});
