import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helpers } from '../core/src/helpers';
import { fetchPrices, NO_PRICE, receivePriceFetch, receivePriceView } from '../product/product-actions';
import { fetchDeals } from '../product/components/deals/deals-actions';
import { isDealActive } from '../product/components/deals';

import useUserData, { getPStoreID } from './useUserData';
import useSubscriptionsPromo from './useSubscriptionsPromo';
import useProductPromos from './useProductPromos';
import useSiteConfig from './useSiteConfig';
import useTestFlags from '../abtest/useTestFlags';
import { getPriceProps } from '../product/get-product-props/cart';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
export const getCustomSaleText = (priceObj, disabled) => {
    const { priceDifference, discountPercent, customSaleText } = priceObj || {};
    if (disabled) {
        return customSaleText;
    }
    //if custom sale text is a percentage or price difference is less than 1, return custom sale text as is
    if (
        (customSaleText && customSaleText.indexOf('%') > -1) ||
        priceDifference < 1 ||
        discountPercent < 1 ||
        isNaN(discountPercent)
    ) {
        return customSaleText;
    }
    const savingsMessage = customSaleText
        ? customSaleText
        : `SAVE ${Helpers.formatCurrency(priceDifference, '$', true)}`;
    return (
        <>
            {savingsMessage} ({Math.floor(discountPercent)}%<b className="perc-save-suffix">&nbsp;{'OFF'}</b>)
        </>
    );
};

const getCTAText = (priceObj, labels) => {
    try {
        const { productType: prdClass, isOOS, preOrder: isPreOrder, backOrderFlag, isComingSoonSku } = priceObj;
        const {
            add2Cart = 'Add to cart',
            invOutOfStock = 'Out of stock',
            preOrder = 'Pre-order now',
            poboOOSMsg = 'Pre-orders sold out',
            customizeBtn = 'Customize & buy',
            comingSoon = 'Coming soon',
            backOrder = 'Back-order',
        } = labels || {};
        let text = priceObj && (priceObj.regularPrice || priceObj.salePrice) ? add2Cart : undefined;
        if (isOOS) {
            text = invOutOfStock;
        }
        if (isPreOrder) {
            text = isOOS ? poboOOSMsg : preOrder;
        }
        if (isComingSoonSku && isOOS) {
            text = comingSoon;
        }
        return {
            //support OOS override for CTO
            customizeText:
                !isOOS && ['cto', 'giftcard'].includes((prdClass || '').toLowerCase()) ? customizeBtn : undefined,
            ctaText: text,
        };
    } catch (e) {
        return {};
    }
};

/**
 * Helper function to scan price map and return sku id for a given catEntryId
 * @param {*} catEntryId
 * @param {*} prices
 * @returns
 */
export const getSKUByItemId = (catEntryId, prices) => {
    return Object.keys(prices).find(sku => {
        return prices[sku].itemId === catEntryId;
    });
};

/**
 * Checks all the possible names given to the catEntryId on a product object
 * @param {*} product
 * @returns
 */
export const getCatEntryId = Helpers.getCatEntryId;
/**
 * Returns the standard aria-label text for a price element
 * @param {*} priceObj
 * @param {*} showListPrice
 * @returns
 */
export const getPriceAriaLabel = (priceObj, showListPrice = true) => {
    const { translations } = priceObj || {};
    const { salePriceSuffix } = translations || {};
    const regularPriceLabel = Helpers.formatCurrency(priceObj.regularPrice, undefined, true);
    const salePriceLabel = Helpers.formatCurrency(priceObj.salePrice, undefined, true);
    return `Sale price starting at ${salePriceLabel}${salePriceSuffix || ''}.${
        showListPrice ? ` Regular price starting at ${regularPriceLabel}${salePriceSuffix || ''}` : ''
    }`;
};

/**
 * Update bundle items with their price
 * @param {*} priceObj
 */
const setBundlePrices = (priceObj, prices, returnEmptyPriceObj) => {
    //if product is a kit attach kit item prices (AS OF NOW HPSERVICES RETURNS UNDERLYING KIT ITEM PRICES WHEN YOU FETCH THE KIT PRICE)
    const { items, productType } = priceObj || {};
    if (productType !== 'GIFTCARD' && items && Object.keys(items).length > 0) {
        priceObj.items = Object.keys(items).reduce((r, key) => {
            let item = items[key];
            let itemPriceObj = Helpers.getProductPrice(item, prices, returnEmptyPriceObj);
            r[key] = { ...itemPriceObj, ...item };
            return r;
        }, {});
    }
};

const getOOSSetting = (product, productSettings) => {
    const { isOOS: serviceOOS, sku, productType = '', stockStatus } = product || {};
    try {
        const { oosProducts = [] } = productSettings || {};
        //if sku is in oosProducts list then it's oos, else fall back to response from HPServices
        const isProductOOS = oosProducts.includes(sku);
        const overrideCTOMessage = ['cto', 'bto'].includes(productType.toLowerCase()) && stockStatus === 'green';
        return {
            isOOS: isProductOOS || serviceOOS,
            //include force OOS so it can be differentiated from a normal OOS sku
            forceOOS: isProductOOS,
            productStockStatus: isProductOOS ? 'grey' : undefined,
            productStockMessage: isProductOOS
                ? 'CURRENTLY OUT OF STOCK'
                : overrideCTOMessage
                  ? 'Build to Order'
                  : undefined,
        };
    } catch (e) {}
    return { isOOS: serviceOOS };
};

/**
 * Memoize price selection to reduce re-renders
 */
export const selectPrices = Helpers.memoize(
    (
        prices,
        products,
        ctoConfigurations,
        user,
        deals,
        labels,
        returnEmptyPriceObj,
        productSettings,
        subPromos,
        productPromos,
        disableCustomSaleText,
        msrp,
    ) => {
        return {
            prices: (products || []).reduce((r, p) => {
                if (!p) {
                    return r;
                }
                if (!p.sku) {
                    p.sku = getSKUByItemId(getCatEntryId(p), prices);
                }
                const pickedPrice = Helpers.getProductPrice(p, prices, returnEmptyPriceObj);
                if (!returnEmptyPriceObj && !pickedPrice) {
                    return r;
                }
                let priceObj = { ...pickedPrice };
                let prodDeals = deals[getCatEntryId(p)];
                const ctoConfig = (ctoConfigurations || {})[p.sku];
                const { items, productType, stockMessage, stockStatus, salePriceSuffix, translations } = priceObj;
                const { isOOS, productStockStatus, productStockMessage, forceOOS } = getOOSSetting(
                    priceObj,
                    productSettings,
                );
                if (user && user.TierPriceMsg && priceObj.tierPrice) {
                    priceObj.tierMsg = user.TierPriceMsg;
                }
                if (isDealActive(prodDeals)) {
                    const deal = prodDeals.activeDeal;
                    priceObj.salePrice = deal.dealPrice * 1;
                    priceObj.deal = prodDeals;
                }

                //HPEUS-3172: exclude bundle item prices
                //setBundlePrices(priceObj, prices, returnEmptyPriceObj);

                //if ctoConfig is available for product and a pre config has been selected with price, use that price
                if (ctoConfig && ctoConfig.price) {
                    priceObj = { ...priceObj, ...ctoConfig.price };
                }
                //copy price object with aditional data from user and product settings
                const newPriceObj = {
                    ...priceObj,
                    pStoreID: getPStoreID(user),
                    isOOS,
                    forceOOS,
                    stockMessage: productStockMessage || stockMessage,
                    stockStatus: productStockStatus || stockStatus,
                    subPromoBanner: (subPromos || {})[p.sku],
                    offers: (productPromos || {})[p.sku],
                    customSaleText: getCustomSaleText(priceObj, disableCustomSaleText),
                    translations: { salePriceSuffix, ...(translations || {}) },
                    msrp,
                };

                r[p.sku] = { ...newPriceObj, ...getCTAText(newPriceObj, labels) };
                return r;
            }, {}),
        };
    },
    (
        prices,
        products,
        ctoConfigurations,
        user,
        deals,
        labels,
        returnEmptyPriceObj,
        productSettings,
        subPromos,
        productPromos,
        disableCustomSaleText,
    ) => {
        const { oosProducts = [] } = productSettings || {};
        const cacheKey = (products || []).reduce((r, p) => {
            if (!p) {
                return '';
            }
            const itemId = getCatEntryId(p);
            let priceKey = p.sku;
            if (!p.sku) {
                priceKey = getSKUByItemId(itemId, prices);
            }
            return r.concat(
                `${priceKey}-${priceKey in prices}-${oosProducts.length}-${itemId in deals}-${
                    (user || {}).TierPriceMsg
                }-${ctoConfigurations && (ctoConfigurations[p.sku] || {}).configCatentryId}-${
                    subPromos && priceKey in subPromos
                }-${productPromos && productPromos[priceKey]?.length}${disableCustomSaleText}`,
            );
        }, ``);
        return cacheKey;
    },
);

/**
 * @typedef {Object} Product
 * @property {string} sku - product SKU
 * @property {string} itemId - legacy id of product used to fetch price
 * @property {string} catentryId - new id for fetching product price
 * @property {Array.<Product>} bundleComponents - array of bundle products that also need prices
 */

/**
 * @typedef {Object} PriceOptions
 * @property {boolean} withIntraDayDeals - Set to true if you want to include intra day deals with the price
 * @property {boolean} withCTOConfigurations - Set to true if you want to include cto configuration information (mainly for PDP)
 * @property {boolean} returnEmptyPriceObj - Defaults to true, if true returns a placeholder price object until response from price API is available
 * @property {boolean} useSubscriptionsPromo - Set to true if you want to fetch subscription promo eSpot data for each product
 */

/**
 * @name usePricePrice
 * @param {Array.<Product>} products
 * @param {PriceOptions} options
 * @returns {object} - Product price hash map by sku
 */
export default function useProductPrice(products = [], options = {}) {
    const dispatch = useDispatch();
    const prices = useSelector(state => state.productData.productInfo.productPrices);
    const productSettings = useSelector(state => state.productData.productInfo.productSettings);
    const subPromos = useSubscriptionsPromo(options.useSubscriptionsPromo ? products : []);
    const productPromos = useProductPromos(options.useProductPromos ? products : []);
    const msrp = useMSRPProps();
    const { userData: user } = useUserData();
    const { enablePricePrefetch } = useSiteConfig();
    const deals = useSelector(state => state.deals);
    const etrStoreData = useSelector(state => state.etrStoreData);
    const { withIntraDayDeals = true, returnEmptyPriceObj = true, disableCustomSaleText } = options;
    const ctoConfigurations = useSelector(state => {
        const { withCTOConfigurations } = options;
        if (withCTOConfigurations) {
            return state.productData.ctoConfigurations;
        }
        return null;
    });

    useEffect(() => {
        let noPrice = (products || []).filter(product => {
            if (!product) {
                return false;
            }
            const { sku, prdClass, product_class } = product;
            const catId = getCatEntryId(product);
            const priceFailure = prices[catId];
            const productType = prdClass || product_class;
            //if sku isn't available try to match catId to sku from prices already fetched
            //this should help reduce refetching if prices when sku is unavailable
            const priceKey = sku || getSKUByItemId(catId, prices);
            //when prices fail to fetch, we get no response back so we dont know the sku
            // which mean we need to check for fetch fail by itemId
            return (
                !(productType in NO_PRICE) && !(priceKey in prices) && (!priceFailure || !priceFailure.priceFetchFailed)
            );
        });
        if (noPrice.length > 0) {
            const ids = noPrice.reduce((r, p) => {
                const id = getCatEntryId(p);
                if (id) {
                    r.push(id);
                }
                return r;
            }, []);
            if (ids.length > 0) {
                dispatch(receivePriceFetch(ids));
                dispatch(fetchPrices(ids, true));
            }
        }
        //check for deals if we haven't already and make sure user data is available first so that deals is properly fetched for private store
        if (withIntraDayDeals && (!deals || !deals.dealsFetched) && user.updatedFromService) {
            dispatch(fetchDeals());
        }
    }, [products]);

    //mark prices for prefect on the server, these will then be fetched along side hydration of React
    if (enablePricePrefetch && process.env.ISNODE && (products || []).length > 0) {
        dispatch(
            receivePriceView(
                products.reduce((list, product) => {
                    const cId = getCatEntryId(product);
                    if (cId && cId.indexOf('USER_DEFINED') < 0) {
                        list.push(cId);
                    }
                    return list;
                }, []),
            ),
        );
    }

    return selectPrices(
        prices,
        products,
        ctoConfigurations,
        user,
        deals,
        etrStoreData.labels,
        returnEmptyPriceObj,
        productSettings,
        subPromos,
        productPromos,
        disableCustomSaleText,
        msrp,
    );
}

const transformProps = Helpers.memoize(
    (product, priceObj) => {
        return getPriceProps(product, priceObj, { hasPriceSeoText: true });
    },
    (product, priceObj) => {
        return `${product?.sku}-${priceObj?.salePrice}-${priceObj?.priceDifference}-${typeof priceObj?.customSaleText}`;
    },
);

export const useGetProductPrice = (product, options) => {
    const { transformPriceProps } = options || {};
    const { prices } = useProductPrice(product ? [product] : EMPTY_ARRAY, options);
    if (!product) {
        return EMPTY_OBJECT;
    }
    return transformPriceProps ? transformProps(product, prices[product.sku]) : prices[product.sku];
};

export const useCTOConfigPrice = product => {
    const ctoConfigurations = useSelector(state => {
        return state.productData.ctoConfigurations;
    });
    return product && ctoConfigurations && ctoConfigurations[product?.sku];
};

export const usePriceSettings = () => {
    return useSelector(state => state.siteConfig?.priceSettings || EMPTY_OBJECT);
};

export const useMSRPProps = (toolTipProps = {}, btnProps = {}) => {
    const priceSettings = useSelector(state => state.siteConfig?.priceSettings || EMPTY_OBJECT);
    const { msrpTooltip } = priceSettings;
    const { children = 'MSRP', title, content } = msrpTooltip || {};
    if (!content) {
        return {};
    }
    return {
        TooltipProps: {
            description: content,
            content,
            contentClassName: 'msrp-tooltip-content',
            onTooltipClose: e => {
                //prevent click from bubbling up to parent for locations where MSRP is embedded with a link
                e.preventDefault();
                e.stopPropagation();
            },
            ...toolTipProps,
        },
        buttonProps: {
            children,
            className: 'msrp-tooltip-btn',
            onClick: e => {
                //prevent click from bubbling up to parent for locations where MSRP is embedded with a link
                e.preventDefault();
                e.stopPropagation();
            },
            ...btnProps,
        },
    };
};
