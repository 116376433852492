import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { Helpers } from '../core/src/helpers';
import { RECEIVE_SEARCH_RESULTS } from './search-filter-actions';

export const DEFAULT_SEARCH_RESULTS = {
    query: {},
    results: [],
    aggregations: {},
    currentPage: 1,
    hits: 0,
    sort: {},
    pageSize: 21,
    totalPages: 1,
};

/**
 * Takes the results from the search client and transforms them
 * in the correct format for the redux store.
 */
export const parseSearchResults = (
    currentResults = DEFAULT_SEARCH_RESULTS,
    searchResults,
    pageNumber = DEFAULT_SEARCH_RESULTS.currentPage,
    sort,
    query,
    resetResults,
    resultMapper,
    customAggregations,
    filterCount,
    dynamicSEO,
) => {
    let mergeFunc = resetResults ? Object.assign : merge;
    const { aggregations } = searchResults;
    let hits = (searchResults.hits && searchResults.hits.total) || 0;
    let formattedResults = cloneDeep({
        hits,
        currentPage: pageNumber,
        results: resetResults ? [] : currentResults.results.slice(),
        query,
        sort,
        aggregations,
        totalPages: Math.max(1, Math.ceil(hits / currentResults.pageSize)),
        filterCount,
        dynamicSEO,
    });
    // add results to paged results
    if (searchResults.hits && searchResults.hits.hits) {
        let { hits } = searchResults.hits;
        if (typeof resultMapper === 'function') {
            hits = hits.map(resultMapper);
        }

        formattedResults.results = [...formattedResults.results, ...hits];
    }

    //add custom aggregates
    if (customAggregations) {
        formattedResults.customAggregations = customAggregations;
    }

    // break refs before return to prevent mutations when switching searchKey
    return mergeFunc({}, cloneDeep(currentResults), formattedResults);
};

/*
   Retrieves search results from redux if there are any
 */
export const getSearchResults = Helpers.memoize(
    (state, searchKey, resultMapper, searchProp) => {
        const { searchFilter = {} } = state;
        let search = searchFilter[searchKey];
        if (search) {
            return parseSearchResults(
                undefined,
                search.searchResults,
                search.pageNumber,
                search.sort,
                search.query,
                false,
                resultMapper,
                search.customAggregations,
                search.filterCount,
                search.dynamicSEO,
            );
        } else {
            return Object.assign(cloneDeep(DEFAULT_SEARCH_RESULTS), cloneDeep(searchProp));
        }
        //only need this for initializing context from redux, so caching on searchKey should be enough
    },
    (state, searchKey) => searchKey,
);

const SearchFilterReducer = (state = {}, action) => {
    if (action.type === RECEIVE_SEARCH_RESULTS) {
        const {
            query,
            searchKey,
            searchResults,
            pageNumber,
            currentPage,
            totalPages,
            hits,
            aggregations,
            customContext,
            sort,
            customAggregations,
            isMerging,
            initialSearchId,
            dynamicSEO,
        } = action;

        let result = {};
        result[searchKey] = {
            query,
            searchResults,
            pageNumber,
            currentPage,
            totalPages,
            hits,
            aggregations,
            customContext,
            sort,
        };
        if (customAggregations) {
            result[searchKey].customAggregations = customAggregations;
        }

        if (dynamicSEO) {
            result[searchKey].dynamicSEO = dynamicSEO;
        }

        if (initialSearchId) {
            result[searchKey].initialSearchId = initialSearchId;
        }

        if (isMerging) {
            const currentSearchResults = state && state[searchKey] && state[searchKey].searchResults;
            result[searchKey].searchResults = {
                ...(currentSearchResults || {}),
                ...(searchResults || {}),
            };
        }

        let mergedResult = merge({}, state, result);
        try {
            let { post_filters, searchId } = result[searchKey].searchResults || {};
            if (post_filters) {
                mergedResult[searchKey].searchResults.post_filters = post_filters;
            }
            if (searchId) {
                mergedResult[searchKey].searchResults.searchId = searchId;
            }
        } catch (e) {}
        return mergedResult;
    } else {
        return state;
    }
};

export default SearchFilterReducer;
