import { useSelector, useDispatch } from 'react-redux';

import { handleClearRecentlyAdded, fetchCartView } from '../utility/utility-actions';
import { EtrAPI } from '../core/src/etr-api-client';
import EtrAPIClient from '../core/src/etr-wcs-api-client';
import useUserData, { isCallCenter } from './useUserData';
import useAddToCart from './useAddToCart';

/**
 * Returns the redux state for the Cart
 * @name useCartState
 * @returns {cartInfo, cartId, clearRecentlyAdded, getCartItem}
 */
export default function useCartState(options = {}) {
    const cartInfo = useSelector(state => state.cartInfo);
    const dispatch = useDispatch();
    const { updatedFromService, items, id } = cartInfo || {};
    const cartViewIsLoading = !cartInfo?.cartView;
    const emptyCart =
        cartInfo?.cartView?.emptyCart ||
        (cartInfo?.cartView && (cartInfo.cartView.orderItem || []).length === 0) ||
        (updatedFromService && items?.length === 0 && !id);
    return {
        cartInfo,
        cartId: cartInfo && cartInfo.id,
        clearRecentlyAdded: () => dispatch(handleClearRecentlyAdded()),
        getCartItem: sku => cartInfo && cartInfo.items && cartInfo.items.find(item => item.pNum === sku),
        setCartView: calculate => dispatch(fetchCartView(calculate)),
        cartViewIsLoading,
        emptyCart,
    };
}

/***
 * Returns cart actions that will refresh cart view after the action is completed
 */
export const useCartActions = () => {
    const dispatch = useDispatch();
    const orderId = useSelector(state => state.cartInfo?.id);
    const { userData } = useUserData();
    const ccf = isCallCenter(userData);
    const { addToCart } = useAddToCart();
    const commonParams = {
        orderId,
        token: 'NA',
        finalView: 'AjaxOrderItemDisplayView',
        loyaltyAmt: '',
        isLoyaltyEligible: '',
        ccf,
    };
    return {
        setPromoCode: (promotionCode, addPromo) => {
            return EtrAPI.cart
                .setPromoCode({ ...commonParams, promoCode: promotionCode, taskType: addPromo ? 'A' : 'R' })
                .then(resp => {
                    dispatch(fetchCartView(false));
                    return resp;
                });
        },
        setZipCode: zipCode => {
            return EtrAPI.cart.setZipCode({ ...commonParams, zipCode, URL: '/' }).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        updateCartItem: (orderItemId, qty) => {
            const { orderId, ccf } = commonParams;
            return EtrAPI.cart.updateCartItem(orderItemId, qty, { orderId, ccf }).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        getDeliveryOptions: () => {
            const { orderId } = commonParams;
            return EtrAPIClient.cart.deliveryOptions({ orderId }).then(resp => {
                return resp;
            });
        },
        getDeliveryOptionsMessage: () => {
            const espotName = 'CartPage-Ship-LearnMoreVISID';
            return EtrAPIClient.component.get('espot', espotName).then(resp => {
                const [espot] = resp?.[espotName];
                return espot?.content;
            });
        },
        getSubscriptionPolicies: () => {
            const espotName = 'My-Subscription-Policies-ViewHeadlessCart';
            return EtrAPIClient.component.get('espot', espotName).then(resp => {
                const [espot] = resp?.[espotName];
                try {
                    return JSON.parse(espot?.content?.[0]);
                } catch (e) {
                    return [
                        {
                            tooltipProps: {
                                title: 'Return policy BIZTEST',
                                contentSource: 'xitem_prodReturnPolicyTooltip',
                            },
                            label: 'Return policy BIZTEST',
                        },
                        {
                            linkProps: {
                                contentSource: 'xitem_subscriptionstermsofserviceurl',
                                children: 'Terms of service',
                                target: '_blank',
                            },
                        },
                        {
                            linkProps: {
                                to: '/us-en/shop/cv/customerservicechangecancelorder',
                                children: 'Cancellation policy',
                                target: '_blank',
                            },
                        },
                    ];
                }
            });
        },
        getRewardsInfo: () => {
            return EtrAPIClient.cart.get({ profileName: 'HP_LoyaltyRewards_Summary' });
        },
        redeemPoints: points => {
            return EtrAPI.cart.redeemPoints(points).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        getRewardsBanner: (loggedIn, isRegistered) => {
            const espotName = loggedIn
                ? isRegistered
                    ? 'LoyaltyRegCartPageMsgVISID'
                    : 'LoyaltyCartPageMsgVISID'
                : 'LoyaltyGuestCartPageMsgVISID';
            return EtrAPIClient.component.get('espot', espotName).then(resp => {
                const [espot] = resp?.[espotName];
                return espot?.content;
            });
        },
        updateShipMethod: shipModeId => {
            const { orderId, ccf } = commonParams;
            return EtrAPI.cart.updateShipMethod({ orderId, shipModeId, ccf }).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        updateFreeGiftSelection: (rewardOptionId, items) => {
            return EtrAPI.cart.updateFreeGiftSelection(rewardOptionId, items).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        viewConfig: itemId => {
            const { orderId } = commonParams;
            return EtrAPIClient.cart.viewConfig({ orderId, itemId }).then(resp => {
                return resp;
            });
        },
        getCarePackInfo: () => {
            const { orderId } = commonParams;
            return EtrAPIClient.cart.carepackInfo({ orderId }).then(resp => {
                return resp;
            });
        },
        addCrossSell: (product, quantiy, eligibleSKU) => {
            return addToCart(product, quantiy, true, undefined, undefined, eligibleSKU, 'crossSell').then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        payPalExpressCheckout: (paypalUrl, returnURL, pageSourceType = 'ShoppingCart') => {
            try {
                const cancelURL = window.location.href;
                const { orderId } = commonParams;
                return EtrAPIClient.cart
                    .paypalExpressCheckout({ paypalUrl, orderId, cancelURL, pageSourceType, returnURL })
                    .then(resp => {
                        const { paymentMessage, paypal_token } = resp;
                        if (paymentMessage == 'NE') {
                            return paypal_token;
                        } else {
                            return Promise.reject();
                        }
                    });
            } catch (e) {
                return Promise.reject(e);
            }
        },
        getAdditionalDeliveryMessages: () => {
            const espotName = 'CartPage-Oversized-Freight';
            return EtrAPIClient.component.get('espot', espotName).then(resp => {
                const [espot] = resp?.[espotName];
                return espot?.content;
            });
        },
    };
};
