export const PageAPI = function (apiConfig, http, helpers) {
    let self = this;
    let path = `${process.env.BASENAME}/app/api/web/page`;
    let seoPath = `${process.env.BASENAME}/app/api/web/seo-config`;
    let elasticSearchPath = `${process.env.BASENAME}/app/api/es/page`;

    self.get = (slug, options = {}, httpConfig = {}) => {
        const { pageKey } = options;
        const apiPath = `${path}/` + (pageKey ? `${slug}/${pageKey}` : slug);

        //remove special options prop
        delete options.pageKey;
        return http.get(apiPath, {
            params: options,
            ...httpConfig,
        });
    };

    self.search = (query = {}) => {
        return http.get(`${path}/search`, {
            params: query,
        });
    };

    self.elasticSearch = (
        filters,
        post_filters,
        aggregates,
        aggregateFilters,
        sort,
        size,
        from,
        bucketSize,
        _source,
    ) => {
        return http.post(`${elasticSearchPath}/search`, {
            filters,
            post_filters,
            aggregates,
            aggregateFilters,
            sort,
            size,
            from,
            bucketSize,
            _source,
        });
    };

    self.getPageComponent = (slug, component, options = {}) => {
        const encodedOptions = options ? `/${encodeURIComponent(JSON.stringify(options))}` : '';
        const apiPath = `${path}/page-component/${slug}/${component}${encodedOptions}`;
        return http.get(apiPath);
    };

    /**
     * Helper function for fetching page template theme
     * @param {*} vanityUrl
     */
    self.getTheme = vanityUrl => {
        const apiPath = `${path}/page-component/theme/${vanityUrl}`;
        return http.get(apiPath);
    };

    self.getSEO = vanityUrl => {
        const apiPath = `${seoPath}/path/${vanityUrl}`;
        return http.get(apiPath);
    };

    self.getIntopicBlogs = (categories, tags) => {
        const filters = { templateKey: 'blog', hosted: 'true', blogArchive: 'false' };
        const postFilters = {
            ...(Array.isArray(categories) && categories.length > 0 ? { categories } : {}),
            ...(Array.isArray(tags) && tags.length > 0 ? { tags } : {}),
        };

        // of the 8 newest blogs fetched, only use the first 4 results that aren't duplicates of the 4 most-viewed blogs
        return Promise.all([
            self.elasticSearch(filters, postFilters, {}, [], { viewCount: 'desc' }, 4, 0, 4),
            self.elasticSearch(filters, postFilters, {}, [], { blogDate: 'desc' }, 8, 0, 8),
        ]).then(([topBlogsResp, newestBlogsResp]) => {
            let topBlogsMap = {},
                newestBlogResults,
                topBlogs,
                newestBlogs;
            try {
                topBlogs = topBlogsResp.data.hits.hits;
                topBlogsMap = topBlogs.reduce((allTopBlogs, blog) => {
                    let { vanityUrl } = (blog && blog._source) || {};
                    if (typeof vanityUrl === 'string') {
                        allTopBlogs[vanityUrl] = 1;
                    }
                    return allTopBlogs;
                }, {});
            } catch (e) {}

            try {
                newestBlogResults = newestBlogsResp.data.hits.hits;
                newestBlogs = newestBlogResults
                    .filter(blog => blog && blog._source && !topBlogsMap[blog._source.vanityUrl])
                    .slice(0, 4);
            } catch (e) {}

            const intopicArticles = [
                ...(Array.isArray(topBlogs) ? topBlogs : []),
                ...(Array.isArray(newestBlogs) ? newestBlogs : []),
            ].reduce((allBlogs, blog) => {
                if (blog && blog._source) {
                    allBlogs.push(blog._source);
                }
                return allBlogs;
            }, []);

            return {
                blogs: intopicArticles,
                categories,
                tags,
            };
        });
    };
};
